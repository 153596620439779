import { faBell, faCalendarPlus, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { faListCheck, faShoppingCart, faUserPlus } from '@fortawesome/free-solid-svg-icons';

export const items = [
  {
    icon: faShoppingCart,
    title: 'Tienda personalizada',
    content: 'Diseña tu página dándole tu impronta con herramientas de diseño y personalización.'
  },
  {
    icon: faListCheck,
    title: 'Catálogo de servicios',
    content: 'Ofrece tus servicios en un mismo lugar: sesiones, turnos, talleres, workshops.'
  },
  {
    icon: faCalendarPlus,
    title: 'Sistema de reservas',
    content: 'Recibe reservas las 24hs, sin intermediarios. Tu agenda se actualiza en tiempo real.'
  },
  {
    icon: faCreditCard,
    title: 'Medios de pago',
    content: 'Cobra por adelantado, registrando pagos en débito, crédito y efectivo, pesos o usd. '
  },
  {
    icon: faBell,
    title: 'Recordatorios automáticos',
    content: 'Automatiza mensajes a clientes por mail y Whatsapp para evitar que te cancelen.'
  },
  {
    icon: faUserPlus,
    title: 'Registro de clientes',
    content: 'Accede a la información que necesitas de las personas que concretan reservas. '
  }
];

export type ItemType = (typeof items)[number];
