import { faCalendar, faCalendarDays, faCheckCircle } from '@fortawesome/free-regular-svg-icons';

export const items = [
  {
    icon: faCalendar,
    title: 'Elige una plantilla de diseño y personaliza tu página',
    content: 'Agrega información de valor sobre vos y tu negocio. Subís fotos, videos y certificaciones.'
  },
  {
    icon: faCalendarDays,
    title: 'Arma tu catálogo de servicios y sus horarios',
    content:
      'Puedes ofrecer servicios, como sesiones, clases o talleres. Definís la modalidad, los precios y medios de pago.'
  },
  {
    icon: faCheckCircle,
    title: 'Comparte el link a la tienda con tus clientes',
    content:
      '¡Listo! La mejor experiencia para vos y tus clientes. Todas tus reservas e inscripciones en piloto automático.'
  }
];

export type ItemType = (typeof items)[number];
