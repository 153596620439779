import EllipsisCircle from '../EllipsisCircle';
import TextChanger from '../TextChanger';
import { ItemType, ProfessionalType, firstColumnVendors, items, secondColumnVendors } from './constants';
import { useScreenSize } from '@/hooks';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare, faCoffee } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { GradientButton } from '@/components/GradientButton';
import { Grid } from '@/components/Grid';

library.add(faCheckSquare, faCoffee);

interface CarrouselItemProps {
  professional: ProfessionalType;
  item: ItemType;
}

const CarrouselItem: FunctionComponent<CarrouselItemProps> = React.memo(({ professional, item }) => {
  return (
    <Column gap={16} className="pt-4">
      <div
        style={{
          borderRadius: '21px',
          overflow: 'hidden',
          position: 'relative',
          width: 180,
          height: 260
        }}
      >
        <Image
          src={professional.image}
          layout="fill"
          objectFit="cover"
          sizes="(max-width: 640px) 180px, 260px"
          alt={professional.name}
          loading="eager"
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.70) 100%)'
          }}
        />

        <div className="absolute bottom-0 p-4 text-white">
          <p className="text-xs">{professional.name}</p>
          <p className="text-sm font-bold">{professional.title}</p>
        </div>
      </div>
      <div
        className="bg-white px-4 py-2 rounded-lg w-[180px]"
        style={{ boxShadow: '8px 8px 20px 0px #C4CAD3' }}
      >
        <Row gap={8} align="center">
          <span
            className={classNames({
              'flex items-center justify-center rounded-full p-1 w-[26px] h-[26px]': true,
              'text-green-500 bg-[#E7FBF6]': item.color === 'green',
              'text-blue-500 bg-[#E7F2FB]': item.color === 'blue'
            })}
          >
            <FontAwesomeIcon
              icon={item.icon}
              width={18}
              height={18}
              color={item.color === 'green' ? '#0AD6A1' : '#0072FF'}
            />
          </span>
          <p className="text-[11px]">{item.text}</p>
        </Row>
      </div>
    </Column>
  );
});

const extendedColumnOne = firstColumnVendors.concat(firstColumnVendors);
const extendedColumnTwo = secondColumnVendors.concat(secondColumnVendors);

interface Props {
  onCTA: (section: string) => void;
}

const Hero: FunctionComponent<Props> = ({ onCTA }) => {
  const { isMobile, isLg } = useScreenSize();

  return (
    <Row className="relative z-0 h-screen justify-center items-center text-black" gap={isMobile ? 0 : 64}>
      {!isMobile && (
        <EllipsisCircle
          color="#0072FF"
          size={350}
          rotation={23.717}
          opacity={0.9}
          blur={120}
          className="absolute top-0 lg:top-10 -right-40"
        />
      )}
      <Column gap={40} style={{ flex: isMobile ? 10 : 6 }}>
        <Column gap={24} className="gap-6">
          <h1 className="text-[50px] md:text-7xl font-black leading-tight">
            ¿Ofreces <br />
            <TextChanger words={['turnos?', 'sesiones?', 'clases?', 'servicios?', 'talleres?']} />
          </h1>
          <h2 className="text-xl md:text-3xl relative">
            Crea tu tienda online en minutos y <br />
            <span
              style={{
                background: 'linear-gradient(148deg, #FF4658 46%, #0072FF 94%, #0AD6A1 98%)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              acepta reservas las 24 horas.
            </span>
            <EllipsisCircle
              color="#0072FF"
              width={200}
              height={25}
              rotation={0}
              opacity={0.9}
              blur={15}
              className="absolute -bottom-44 left-0"
            />
            <EllipsisCircle
              color="#FF4658"
              width={150}
              height={25}
              rotation={0}
              opacity={0.5}
              blur={30}
              className="absolute -bottom-44 left-44"
            />
          </h2>
        </Column>
        <div>
          <GradientButton onClick={() => onCTA('Hero')}>Empezar gratis</GradientButton>
        </div>
      </Column>
      {!isMobile && (
        <Column className="h-full px-10 overflow-hidden relative">
          <Grid as="div" gap={20} columns={2}>
            <Column className="column-animation">
              {extendedColumnOne.map((_p, i) => {
                const professional = extendedColumnOne[i % extendedColumnOne.length];
                const item = items[i % items.length];

                return <CarrouselItem key={i} professional={professional} item={item} />;
              })}
            </Column>
            {isLg && (
              <Column className="column-animation-offset">
                {extendedColumnTwo.map((_p, i) => {
                  const professional = extendedColumnTwo[i % extendedColumnTwo.length];
                  const item = items[i % items.length];

                  return <CarrouselItem key={i} professional={professional} item={item} />;
                })}
              </Column>
            )}
          </Grid>
          <div
            className="absolute w-full h-24 -bottom-1"
            style={{ background: 'linear-gradient(360deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)' }}
          />
        </Column>
      )}
    </Row>
  );
};

export default Hero;
