import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import Image from 'next/legacy/image';
import { motion } from 'framer-motion';
import TextChanger from '../TextChanger';
import { useScreenSize } from '@/hooks';
import { GradientButton } from '@/components/GradientButton';
import { Column } from '@/components/Column';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

interface Props {
  onCTA: (section: string) => void;
}
const Workshops: FunctionComponent<Props> = ({ onCTA }) => {
  const { isMd, isMobile } = useScreenSize();

  const [activeCard, setActiveCard] = useState(0);
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const cards = [
    `${IMAGE_CDN_URL}/landing/agora-vendor-tarjeta-1.webp`,
    `${IMAGE_CDN_URL}/landing/agora-vendor-tarjeta-2.webp`,
    `${IMAGE_CDN_URL}/landing/agora-vendor-tarjeta-3.webp`
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    let timer: any;

    if (isVisible) {
      timer = setInterval(() => {
        setActiveCard((prevActiveCard) => (prevActiveCard + 1) % cards.length);
      }, 2000);
    }

    return () => clearInterval(timer);
  }, [isVisible]);

  const positions = [
    { top: isMobile ? '-100px' : '-60px', left: isMobile ? '70px' : '110px' },
    { top: isMobile ? '-140px' : '-140px', left: isMobile ? '20px' : '30px' },
    { top: isMobile ? '-170px' : '-200px', left: isMobile ? '-20px' : '0px' }
  ];

  const getCardPosition = (index: any) => {
    const diff = (index - activeCard + cards.length) % cards.length;
    return positions[diff];
  };

  const getCardzIndex = (index: any) => {
    const diff = (index - activeCard + cards.length) % cards.length;
    return 2 + (cards.length - 1) - diff;
  };

  return (
    <div
      ref={containerRef}
      className="flex flex-col lg:relative static lg:flex-row justify-center xl:items-center pt-10 lg:pt-20 lg:pb-30 lg:mb-10 pb-10 mb-10 gap-16"
    >
      <div style={{ flex: 7 }} className="mt-36 lg:mt-0 relative lg:static w-full h-full order-2 lg:order-1">
        <div className="relative">
          {cards.map((card, index) => (
            <motion.div
              key={index}
              initial={{ filter: 'grayscale(1)', opacity: 0.9, zIndex: getCardzIndex(index) }}
              animate={{
                filter: activeCard === index ? 'grayscale(0)' : 'grayscale(1)',
                opacity: activeCard === index ? 1 : 0.9,
                zIndex: getCardzIndex(index),
                top: getCardPosition(index).top,
                left: getCardPosition(index).left,
                scale: activeCard === index ? 1 : 0.8
              }}
              transition={{ duration: 0.5 }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className="absolute max-h-[272px] h-[80%] w-[80%] max-w-[642px]"
            >
              <Image
                src={card}
                width={642}
                height={272}
                layout="responsive"
                alt="workshops"
                loading="lazy"
                className="absolute"
              />
            </motion.div>
          ))}
        </div>
      </div>
      <Column style={{ flex: 6 }} gap={8} className="lg:ml-5 order-1 lg:order-2">
        <h2 className="text-2xl sm:text-4xl font-bold leading-snug">
          ¿Organizás{' '}
          <TextChanger
            words={['eventos?', 'workshops?', 'cursos?', 'seminarios?', 'talleres?', 'masterclasses?']}
          />
        </h2>
        <p className="font-bold text-2xl sm:text-4xl">¡Gestionalos con Ágora!</p>
        <p className="pt-2 text-base">
          Pueden ser online o presenciales y, si tienes clientes en el
          {!isMobile && <br />} exterior, podrán pagarte en dólares sin complicaciones.
        </p>
        <div className="pt-4">
          <GradientButton onClick={() => onCTA('Events')}>Quiero vender mis eventos</GradientButton>
        </div>
      </Column>
    </div>
  );
};

export default Workshops;
