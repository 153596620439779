import EllipsisCircle from '../EllipsisCircle';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';
import { FloatingMoon } from '../FloatingMoon';
import { googleCalendar, mercadoPagoIcon, zoom } from '@/static/images';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import useVisibilityTrigger from '@/hooks/useVisibilityTrigger';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

const items = [
  'Conocer los servicios que ofreces',
  'Agendar su turno en cualquier momento',
  'Inscribirse a tus cursos o eventos',
  'Pagar online, con transferencia o efectivo',
  'Recibir recordatorios por mail y WhatsApp',
  'Reagendar o cancelar su turno'
];

const ListItem: FunctionComponent<{ item: string }> = ({ item }) => (
  <Row gap={8} align="center">
    <span className="text-blue-500">✓</span>
    <span className="text-sm md:text-base">{item}</span>
  </Row>
);

const ClientExperience: FunctionComponent = () => {
  const { isMobile } = useScreenSize();
  const { isVisible, elementRef } = useVisibilityTrigger({
    threshold: 0.1
  });

  const buttonVariants = {
    hidden: { scale: 0.4, opacity: 0 },
    visible: { scale: 1, opacity: 1 }
  };
  const commonButtonsStyles =
    'shadow-[3.2px_3.2px_0px_0px_#032646] border border-[#032646] rounded-lg ml-0.5 w-[280px] sm:w-[275px] h-10 bg-white flex items-center justify-center z-10';
  return (
    <Row className="relative py-14 lg:py-20 flex-col-reverse lg:flex-row" gap={24} align="center">
      <EllipsisCircle
        color="#FF4658"
        size={300}
        rotation={23.717}
        opacity={isMobile ? 0.2 : 0.15}
        blur={40}
        className="absolute bottom-56 left-0 md:-bottom-20 md:-left-10"
      />
      {isMobile && (
        <EllipsisCircle
          color="#0072FF"
          size={300}
          rotation={23.717}
          opacity={0.2}
          blur={40}
          className="absolute bottom-0 right-0"
        />
      )}
      <Column
        justify="center"
        align="center"
        style={{ flex: isMobile ? 1 : 3 }}
        className="relative md:order-1"
      >
        <div className="relative w-[300px] h-[550px]">
          <FloatingMoon
            className="w-14 h-14 rounded-full z-10 bg-white absolute top-10 right-0 flex items-center justify-center text-[#0072FF] text-[20px] font-bold shadow-[12px_12px_31px_0px_#C4CAD3]"
            isVisible={isVisible}
          >
            <Image src={mercadoPagoIcon} alt="mercado-pago" width={35} height={35} />
          </FloatingMoon>
          <FloatingMoon
            className="w-9 h-9 rounded-full z-10 bg-white absolute top-24 -right-5 flex items-center justify-center text-[#0072FF] text-[20px] font-bold shadow-[9px_9px_22px_0px_#C4CAD3]"
            isVisible={isVisible}
          >
            <Image src={zoom} width={20} height={20} alt="zoom" />
          </FloatingMoon>
          <Column gap={10} className="absolute top-60 sm:top-64">
            <motion.div
              ref={elementRef}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className={commonButtonsStyles}
              initial="hidden"
              animate={isVisible ? 'visible' : 'hidden'}
              variants={buttonVariants}
              transition={{ duration: 1 }}
            >
              Mis servicios
            </motion.div>
            <motion.div
              ref={elementRef}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className={commonButtonsStyles}
              initial="hidden"
              animate={isVisible ? 'visible' : 'hidden'}
              variants={buttonVariants}
              transition={{ duration: 1 }}
            >
              Próximos talleres
            </motion.div>
            <motion.div
              ref={elementRef}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              className={commonButtonsStyles}
              initial="hidden"
              animate={isVisible ? 'visible' : 'hidden'}
              variants={buttonVariants}
              transition={{ duration: 1 }}
            >
              Mi agenda
            </motion.div>
          </Column>
          <motion.div
            initial="hidden"
            animate={isVisible ? 'visible' : 'hidden'}
            variants={{
              hidden: { opacity: 0 },
              visible: { opacity: 1 }
            }}
            transition={{ duration: 1 }}
          >
            <Image
              alt="agora-storefront-2"
              src={`${IMAGE_CDN_URL}/landing/agora-storefront-2.png` || ''}
              layout="fill"
              loading="eager"
            />
          </motion.div>
          <FloatingMoon
            className="w-14 h-14 rounded-full z-10 bg-white absolute bottom-12 -left-2 flex items-center justify-center text-[#0072FF] text-[20px] font-bold shadow-[9px_9px_22px_0px_#C4CAD3]"
            isVisible={isVisible}
          >
            <FontAwesomeIcon icon={faWhatsapp} size="xl" color="#25D366" />
          </FloatingMoon>
          <FloatingMoon
            className="w-9 h-9 rounded-full z-10 bg-white absolute bottom-28 -left-5 flex items-center justify-center text-[#0072FF] text-[20px] font-bold shadow-[11px_11px_28px_0px_#C4CAD3]"
            isVisible={isVisible}
          >
            <Image src={googleCalendar} alt="google-calendar" width={20} height={20} />
          </FloatingMoon>
        </div>
      </Column>
      <Column style={{ flex: isMobile ? 1 : 7 }} gap={16} className="md:order-2">
        <h2 className="text-2xl md:text-[40px] font-bold">¡Mejora la experiencia de tus clientes!</h2>
        <h4 className="md:text-2xl font-bold">A través de tu tienda online, van a poder...</h4>
        {isMobile ? (
          <Row className="pt-4" gap={16}>
            <Column gap={8}>
              {items.map((item) => (
                <ListItem key={item} item={item} />
              ))}
            </Column>
          </Row>
        ) : (
          <Row className="pt-4" gap={16}>
            <Column gap={8}>
              {items.slice(0, 3).map((item) => (
                <ListItem key={item} item={item} />
              ))}
            </Column>
            <Column gap={8}>
              {items.slice(3, 6).map((item) => (
                <ListItem key={item} item={item} />
              ))}
            </Column>
          </Row>
        )}
      </Column>
    </Row>
  );
};

export default ClientExperience;
