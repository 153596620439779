import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons';
import { faCalendar, faDollarSign, faTicket } from '@fortawesome/free-solid-svg-icons';

const IMAGE_CDN_URL = process.env.NEXT_PUBLIC_IMAGE_CDN_URL;

export const firstColumnVendors = [
  {
    name: 'Facundo Vindis',
    title: 'Academia de cejas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_14.webp`
  },
  {
    name: 'Florencia Murillo',
    title: 'Lash master',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_21.webp`
  },
  {
    name: 'Natalia Acosta',
    title: 'Especialista en pestañas, cejas y uñas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_22.webp`
  },
  {
    name: 'Chiara Salinas',
    title: 'Lash trainer internacional',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_24.webp`
  },
  {
    name: 'Nayme Fuentes',
    title: 'Estudio de belleza',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_11.webp`
  }
];

export const secondColumnVendors = [
  {
    name: 'Daniela Benítez',
    title: 'Especialista en cejas y pestañas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_20.webp`
  },
  {
    name: 'Nashyra Esprella',
    title: 'Lami trainer internacional',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_5.webp`
  },
  {
    name: 'Sinner Studio',
    title: 'Centro de belleza',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_7.webp`
  },
  {
    name: 'Sol Almiron',
    title: 'Lash trainer certificada',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_23.webp`
  },
  {
    name: 'Cona Studio',
    title: 'Estudio de cejas y pestañas',
    image: `${IMAGE_CDN_URL}/landing/profesional_agora_10.webp`
  }
];

export const items = [
  {
    text: 'Juana reagendó su turno',
    icon: faCalendarAlt,
    color: 'blue'
  },
  {
    text: 'Recibiste un pago en USD',
    icon: faDollarSign,
    color: 'green'
  },
  {
    text: '¡Felicitaciones! Vendiste todas las entradas.',
    icon: faTicket,
    color: 'blue'
  }
];

export type ProfessionalType = (typeof firstColumnVendors)[number];
export type ItemType = (typeof items)[number];
