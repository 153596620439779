import { SupportedCountriesE } from '@/types/cyclone/models';

interface PolygonI {
  lat: number;
  lng: number;
}

export const isPointInPolygon = (lat: number, long: number, polygon: PolygonI[]): boolean => {
  const x = lat;
  const y = long;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].lat;
    const yi = polygon[i].lng;
    const xj = polygon[j].lat;
    const yj = polygon[j].lng;

    const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }

  return inside;
};

// Note: Used this (https://stackoverflow.com/a/24680708) answer from SO to calculate
// if a certain point is within a circle.
export const isPointInCircle = (
  circleLat: number,
  circleLong: number,
  rad: number,
  lat: number,
  long: number
) => {
  const ky = 40000 / 360;
  const kx = Math.cos((Math.PI * circleLat) / 180.0) * ky;
  const dx = Math.abs(circleLong - long) * kx;
  const dy = Math.abs(circleLat - lat) * ky;
  return Math.sqrt(dx * dx + dy * dy) <= rad;
};

export const formatGoogleMapsAddress = (address: string) => {
  const splitAddress = address.split(',');
  const formattedAddress = splitAddress[0] + ',' + splitAddress[1];
  return formattedAddress;
};

export const getSupportedCountriesFromCode = (code: 'ar' | 'uy' | 'cl') => {
  switch (code) {
    case 'ar':
      return SupportedCountriesE.ARGENTINA;
    case 'uy':
      return SupportedCountriesE.URUGUAY;
    case 'cl':
      return SupportedCountriesE.CHILE;
  }
};

export const getSymbolFromCountry = (country: string) => {
  switch (country) {
    case 'Chile':
      return '$';
    case 'Uruguay':
      return 'UY$';
    default:
      return '$';
  }
};

export const formatAssetAddress = (address: string, country?: string, state?: string) => {
  const splitAddress = address.split(',').map((part) => part.trim());

  // Si no se proporciona el estado, asumir que es la penúltima parte de la dirección
  if (!state && splitAddress.length > 1) {
    state = splitAddress[splitAddress.length - 2];
  }

  // Si no se proporciona el país, asumir que es la última parte de la dirección
  if (!country && splitAddress.length > 0) {
    country = splitAddress[splitAddress.length - 1];
  }

  // La ciudad es todo lo demás excepto el estado y el país
  const cityParts = splitAddress.slice(0, -2);
  const city = cityParts.join(', ');

  // Formatear la dirección final
  const formattedAddress = {
    city: city.trim(),
    state: state || '',
    country: country || ''
  };

  return formattedAddress;
};
