import { IntegrationsMarquee } from './IntegrationsMarquee';
import { icons } from './constants';
import { Column } from '@/components/Column';
import { Row } from '@/components/Row';
import { useScreenSize } from '@/hooks';
import Image from 'next/legacy/image';
import React, { FunctionComponent } from 'react';
import EllipsisCircle from '../EllipsisCircle';

const Integrations: FunctionComponent = () => {
  const { isLg } = useScreenSize();
  if (!isLg) {
    return (
      <React.Fragment>
        <Column className="justify-center items-center pt-10 md:mt-20 mb-8">
          <h2 className="text-2xl text-center font-bold">
            Integra a tu tienda <br /> las aplicaciones que ya usas
          </h2>
        </Column>
        <IntegrationsMarquee />
      </React.Fragment>
    );
  }

  return (
    <Column className="justify-center items-center py-20 relative" gap={32}>
      <h2 className="text-3xl font-bold">Integra a tu tienda las aplicaciones que ya usas</h2>
      <EllipsisCircle
        color="#0072FF"
        size={250}
        rotation={23.717}
        opacity={0.1}
        blur={40}
        className="absolute -top-40 -right-80"
      />
      <EllipsisCircle
        color="#FF4658"
        size={300}
        rotation={23.717}
        opacity={0.1}
        blur={40}
        className="absolute top-0 -left-0"
      />
      <Row justify="space-between" className="w-full">
        {icons.map((icon, index) => (
          <div key={`icons-${index}`} className="flex justify-center items-center">
            <Image
              objectFit="contain"
              src={icon.src}
              width={icon.width}
              height={icon.height}
              alt={icon.alt}
              loading="lazy"
            />
          </div>
        ))}
      </Row>
    </Column>
  );
};

export default Integrations;
